import { Plugin } from 'vue'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import { usePushService } from '/~/plugins/push-service'
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links'
import { useRouter } from '/~/state/route'
import { useApplication } from '/~/state/application'

function getPath(url: string) {
  // smoke check
  if (!url.includes('wnhub.io')) {
    return false
  }
  let path = url.split('.io').pop()

  try {
    // check for subdomain
    const parsedUrl = new URL(url.split('link=')[1])
    const hostname = parsedUrl.hostname
    const subdomain =
      hostname.split('.').length === 3 ? hostname.split('.')[0] : ''

    if (subdomain) {
      path = `/events/${subdomain}` + url.split('.io').pop()
    }
  } catch (e: any) {
    console.log('app: failed to parse url', url)
  }

  return path
}

function onAppUrlOpen(event: URLOpenListenerEvent) {
  const router = useRouter()
  const deepLinkPrefix = 'app-link'

  console.log('on appUrlOpen', event.url)

  let path = getPath(event.url)

  if (path && path.includes(deepLinkPrefix)) {
    path = path.replace('/' + deepLinkPrefix, '')
    console.log('app: remove deep link prefix', path)
  }

  if (path && !path.includes('/events/')) {
    window.open(path, '_self')
    return
  }

  console.log('app: open url', path, router)
  if (path && router) {
    router.push({ path: path, query: Object.fromEntries(new URLSearchParams(path.split('?')[1])) })
  }
}

function addAppUrlOpenHandler() {
  App.addListener('appUrlOpen', onAppUrlOpen)
}

const plugin: Plugin = async function(_app, options) {
  // StatusBar
  try {
    await StatusBar.setStyle({ style: Style.Dark })
    useApplication().platform === 'android' && (await StatusBar.setBackgroundColor({ color: '#3b82f6' }))
  } catch (error: any) {
    console.log('error on StatusBar configure', error)
  }

  // Navigation
  App.addListener('backButton', () => {
    if (options.router) {
      options.router.go(-1)
    }
  })

  addAppUrlOpenHandler()

  function listenToDeepLinkOpen() {
    FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
      if (!useApplication().isIOS) {
        console.log('on deepLinkOpen', data?.url)
        onAppUrlOpen(data)
      }
    })
  }

  listenToDeepLinkOpen()

  usePushService().addListeners()
  await usePushService().registerNotifications()
}

export default plugin
