import { Transform, Type, Expose } from 'class-transformer'
import { ConfigInterface } from '/~/plugins/config'
import { BaseModel } from './baseModel'
import { getLocalMedia, Media } from './media'
import { useLocale } from '/-/plugins/locale'

export enum EventType {
  Default,
  Course,
}

export type EventRequiredFieldType = 'country' | 'city' | 'job' | 'company' | 'avatar'

type EventRequiredField = {
  name: string
  required: boolean
  type: EventRequiredFieldType
}

export class Event extends BaseModel {
  id!: number
  slug!: string
  config!: ConfigInterface
  latitude!: string
  longitude!: string
  name!: string
  tagline?: string
  points?: string
  timezone!: string
  host!: string
  address!: string
  description?: string

  @Expose({ name: 'preview_description' })
  previewDescription?: string

  @Type(() => Media)
  background!: Media | Media[]

  @Type(() => Media)
  logo!: Media | Media[]

  @Expose({ name: 'date_from' })
  dateFrom!: string

  @Expose({ name: 'date_to' })
  dateTo!: string

  @Expose({ name: 'hub_date_from' })
  hubDateFrom!: string

  @Expose({ name: 'hub_date_to' })
  hubDateTo!: string

  @Expose({ name: 'is_online' })
  isOnline!: boolean

  @Expose({ name: 'is_onsite' })
  isOnsite!: boolean

  @Transform(({ value }) => !!value, { toClassOnly: true })
  is_all_users!: boolean

  @Transform(({ value }) => !!value, { toClassOnly: true })
  auto_reg!: boolean

  @Transform(({ value }) => !!value, { toClassOnly: true })
  active!: boolean

  type!: EventType

  @Expose({ name: 'full_address' })
  fullAddress!: string | null

  @Expose({ name: 'address_link' })
  addressLink!: string | null

  city!: string | null

  @Expose({ name: 'required_fields' })
  requiredFields?: EventRequiredField[] = []

  get position() {
    return {
      lat: this.latitude,
      lng: this.longitude,
    }
  }

  get title() {
    return this.tagline || this?.name
  }

  get logoLocal() {
    const { lang } = useLocale()

    if (!this.logo) { return null }

    if (Array.isArray(this.logo)) {
      return getLocalMedia(this.logo, lang.value)
    } else {
      return this.logo
    }
  }

  get backgroundLocal() {
    const { lang } = useLocale()

    if (!this.background) { return null }

    if (Array.isArray(this.background)) {
      return getLocalMedia(this.background, lang.value)
    } else {
      return this.background
    }
  }
}
export class SortedEvents extends BaseModel {
  @Type(() => Event)
  past: Event[] = []

  @Type(() => Event)
  current: Event[] = []

  @Type(() => Event)
  upcoming: Event[] = []
}

export class SortedEventsGroup extends BaseModel {
  @Type(() => SortedEvents)
  user!: SortedEvents

  @Type(() => SortedEvents)
  all!: SortedEvents
}
