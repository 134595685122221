import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from '@litvinovvo/dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import UpdateLocale from 'dayjs/plugin/updateLocale'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import calendar from 'dayjs/plugin/calendar'
import minMax from 'dayjs/plugin/minMax'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'
import 'dayjs/locale/en'
import { watch } from 'vue'
import { useLocale } from '/-/plugins/locale'

function plural(word: string, num: number) {
  const forms = word.split('_')
  return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]) // eslint-disable-line
}
function relativeTimeWithPlural(number: number, withoutSuffix: boolean, key: 'mm' | 'hh' | 'dd' | 'MM' | 'yy' | 'm') {
  const format = {
    mm: withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
    hh: 'час_часа_часов',
    dd: 'день_дня_дней',
    MM: 'месяц_месяца_месяцев',
    yy: 'год_года_лет'
  }

  if (key === 'm') {
    return withoutSuffix ? 'минута' : 'минуту'
  }

  return `${number} ${plural(format[key], +number)}`
}

dayjs.extend(UpdateLocale)
dayjs.extend(LocalizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(minMax)
dayjs.extend(calendar)

export default {
  install: () => {
    const { lang } = useLocale()

    dayjs.locale(lang.value)

    dayjs.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
      }
    })

    dayjs.updateLocale('ru', {
      relativeTime: {
        future: 'через %s',
        past: '%s назад',
        s: 'несколько секунд',
        m: relativeTimeWithPlural,
        mm: relativeTimeWithPlural,
        h: 'час',
        hh: relativeTimeWithPlural,
        d: 'день',
        dd: relativeTimeWithPlural,
        M: 'месяц',
        MM: relativeTimeWithPlural,
        y: 'год',
        yy: relativeTimeWithPlural
      }
    })

    watch(lang, (lang) => dayjs.locale(lang))
  }
}

export {
  dayjs,
}
