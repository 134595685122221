import { h, resolveComponent } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import NetworkError from '/~/views/errors/network-error.vue'
import { useApplication } from '/~/state/application'
import { Routes, RouterProps, RouterGameProps, RouterLectureProps, RouterLessonProps, EVENT_ROUTE_PREFIX } from './types'
import { usePushService, PushActionType } from '/~/plugins/push-service'

const { isGeneral } = useApplication()

const emptyRouteComponent = {
  render: () => h(resolveComponent('router-view')),
}

const commonRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: emptyRouteComponent,
        meta: {
          title: 'Home',
          rootPage: true,
          fullscreen: true
        }
      },
      {
        path: 'expo',
        name: 'expo',
        component: () => import('../views/expo/expo.vue'),
        meta: {
          title: 'Expo',
          rootPage: true,
          fullscreen: true
        },
      },
    ]
  },
  {
    path: 'program',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'program',
        component: () => import('../views/program/program-view.vue'),
        meta: {
          title: 'Program',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'frame',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'frame',
        component: () => import('../views/frame/frame-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Frame',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'contacts',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'contacts',
        component: () => import('../views/contacts/contacts-view.vue'),
        meta: {
          title: 'Contacts',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'favorites',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'favorites',
        component: () => import('../views/favorites/favorites-root.vue'),
        redirect: { name: 'favorites-users' },
        children: [
          {
            path: 'users',
            name: 'favorites-users',
            component: () => import('../views/favorites/favorites-users.vue'),
            meta: {
              title: 'Users'
            }
          },
          {
            path: 'lectures',
            name: 'favorites-lectures',
            component: () => import('../views/favorites/favorites-lectures.vue'),
            meta: {
              title: 'Lectures'
            }
          },
          {
            path: 'companies',
            name: 'favorites-companies',
            component: () => import('../views/favorites/favorites-companies.vue'),
            meta: {
              title: 'Companies'
            }
          }
        ],
        meta: {
          title: 'Favorites',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'notifications',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'notifications',
        component: () => import('../views/notifications/notification-list.vue'),
        meta: {
          title: 'Notifications',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'stub',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'stub',
        component: () => import('../views/stub/stub-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Stub',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'partyspace',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'partyspace',
        component: () => import('../views/partyspace/partyspace-view.vue'),
        meta: {
          title: 'Party Space',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'lectures',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: ':id/:lectureId?',
        name: 'lectures',
        component: () => import('../views/lectures/lectures-root.vue'),
        props: ({ params }: RouterLectureProps) => ({
          id: params.id,
          lectureId: params.lectureId
        }),
        children: [
          {
            path: '',
            name: 'lectures-main',
            component: () => import('../views/lectures/lectures-main.vue'),
            props: ({ params }: RouterLectureProps) => ({
              id: params.id,
              lectureId: params.lectureId
            }),
            meta: {
              title: 'Lectures',
              rootPage: true
            }
          }
        ]
      }
    ]
  },
  {
    path: 'lectures-archive',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'lectures-archive',
        component: () => import('../views/lectures/lectures-archive-list.vue'),
        meta: {
          title: 'Archive of lectures',
          rootPage: true
        }
      },
      {
        path: ':id',
        name: 'lectures-archive-view',
        component: () => import('../views/lectures/lectures-archive-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Archived Lecture'
        }
      }
    ]
  },
  {
    path: 'companies',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'companies-list',
        component: () => import('../views/companies/companies-list/companies-list-entry.vue'),
        meta: {
          title: 'Companies',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      },
      {
        path: ':id',
        name: 'company-view',
        component: () => import('../views/companies/company-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Company'
        }
      },
    ]
  },
  {
    path: 'members',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: Routes.MembersList,
        component: () => import('../views/members/members-list/members-list-entry.vue'),
        meta: {
          title: 'Members',
          rootPage: true,
          isForbiddenOnGeneral: true,
        }
      },
      {
        path: ':id',
        name: 'member-view',
        component: () => import('../views/members/member-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        children: [
          {
            path: 'meeting',
            name: 'create-meeting',
            component: () => import('../views/meetings/meeting-form.vue'),
            props: ({ params }) => ({
              invitedMemberId: parseInt(params.id as string)
            })
          },
          {
            path: 'create-meeting',
            name: 'calendar-create-with-member',
            component: () => import('../views/calendar/components/meeting-form-modal.vue'),
            props: ({ params }: any) => ({
              invitedMemberId: parseInt(params.id as string)
            }),
          },
        ],
        meta: {
          title: 'Member'
        }
      },
    ]
  },
  {
    path: 'meetings',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'meetings',
        component: () => import('../views/meetings/meetings-root.vue'),
        redirect: { name: 'meetings-list' },
        children: [
          {
            path: 'list',
            name: 'meetings-list',
            component: () => import('../views/meetings/meetings-list.vue'),
            children: [
              {
                path: ':id',
                name: 'meeting-view',
                component: () => import('../views/meetings/meeting-modal-view.vue'),
                props: ({ params }: any) => ({
                  id: parseInt(params.id),
                  status: parseInt(params.status),
                  isInvite: params.isInvite ? params.isInvite === 'true' : undefined,
                }),
                meta: {
                  title: 'Meeting View',
                  saveScrollPosition: true
                }
              },
            ],
            meta: {
              title: 'Meetings List'
            }
          },
          {
            path: 'calendar',
            name: 'meetings-calendar',
            component: () => import('../views/meetings/meetings-calendar.vue'),
            /* props: ({ query }) => ({
                  start: query.start,
                  end: query.end
                }), */
            children: [
              {
                path: ':id',
                name: 'calendar-meeting-view',
                component: () => import('../views/meetings/meeting-modal-view.vue'),
                props: ({ params }: any) => ({
                  id: parseInt(params.id)
                })
              },
            ],
            meta: {
              title: 'Meetings Calendar'
            }
          }
        ],
        meta: {
          title: 'Meetings',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'calendar',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'calendar',
        component: () => import('../views/calendar/calendar.vue'),
        children: [
          {
            path: ':id',
            name: 'calendar-view',
            component: () => import('../views/calendar/components/meeting-view-modal.vue'),
            props: ({ params }: any) => ({
              id: parseInt(params.id),
              useSaved: params.useSaved === 'true',
            }),
            meta: {
              title: 'View Meeting',
            }
          },
          {
            path: 'create',
            name: 'calendar-create',
            component: () => import('../views/calendar/components/meeting-form-modal.vue'),
            props: ({ query }: any) => ({
              initialStartTimestampSec: query.start ? parseInt(query.start) : undefined,
              invitedMemberId: query.member ? parseInt(query.member) : undefined,
            }),
            meta: {
              title: 'Create Meeting',
            }
          },
          {
            path: ':id/edit',
            name: 'calendar-edit',
            component: () => import('../views/calendar/components/meeting-form-modal.vue'),
            props: ({ params }: any) => ({
              id: parseInt(params.id),
            }),
            meta: {
              title: 'Edit Meeting',
            }
          },
        ],
        meta: {
          title: 'Meetings',
          rootPage: true,
          saveScrollPosition: true
        }
      },
    ]
  },
  {
    path: 'profile',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('../views/profile/profile-view.vue'),
        meta: {
          title: 'Profile',
          rootPage: true
        }
      },
      {
        path: 'settings',
        name: 'profile-settings',
        component: () => import('../views/profile/profile-settings-root/profile-settings-root-entry.vue'),
        redirect: { name: 'profile-main' },
        children: [
          {
            path: 'main',
            name: 'profile-main',
            component: () => import('../views/profile/profile-settings-main/profile-settings-main-entry.vue'),
            meta: {
              title: 'Profile Settings'
            }
          },
          {
            path: 'account',
            name: 'profile-account',
            component: () => import('../views/profile/profile-settings-account.vue'),
            meta: {
              title: 'Profile Account'
            }
          },
          {
            path: 'company',
            name: 'profile-company',
            component: () => import('../views/profile/profile-settings-company.vue'),
            meta: {
              title: 'Profile Company'
            }
          },
          {
            path: 'company-members',
            name: 'profile-company-members',
            component: () => import('../views/profile/profile-settings-company-members.vue'),
            meta: {
              title: 'Profile Company Members'
            }
          },
          {
            path: 'security',
            name: 'profile-security',
            component: () => import('../views/profile/profile-settings-security/profile-settings-security-entry.vue'),
            meta: {
              title: 'Profile Security'
            }
          },
        ]
      },
      {
        path: 'create_game',
        name: 'game-create',
        component: () => import('../views/profile/profile-game-form.vue'),
        meta: {
          title: 'Profile Game'
        }
      },
      {
        path: 'edit_game/:id',
        name: 'game-edit',
        component: () => import('../views/profile/profile-game-form.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Profile Game'
        }
      },
    ]
  },
  {
    path: 'vacancies',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'vacancies-list',
        component: () => import('../views/vacancies/vacancies-list/vacancies-list-entry.vue'),
        meta: {
          title: 'Vacancies',
          rootPage: true
        }
      },
      {
        path: ':id',
        name: 'vacancies-view',
        component: () => import('../views/vacancies/vacancies-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id)
        }),
        meta: {
          title: 'Vacancy'
        }
      },
    ]
  },
  {
    path: 'video-call',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'video-call-view',
        component: () => import('../views/video-call/video-call-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Video Call',
          fullscreen: true
        }
      },
    ]
  },
  {
    path: 'news',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('../views/news/news-list.vue'),
        meta: {
          title: 'News',
          rootPage: true,
        }
      },
      {
        path: ':id',
        name: 'news-view',
        component: () => import('../views/news/news-item-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'News View',
        }
      },
    ]
  },
  {
    path: 'volunteer',
    name: 'volunteer',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'volunteer-home',
        redirect: { name: 'badges-list' },
      },
      {
        path: 'badges',
        name: 'badges',
        component: () => import('../views/volunteer/volunteer-core.vue'),
        children: [
          {
            path: '',
            name: 'badges-list',
            component: () => import('../views/volunteer/badges/main/badges-main-users-list.vue'),
            meta: {
              title: 'Badges List',
              rootPage: true
            },
          },
          {
            path: ':userId/:badgeId',
            name: 'badges-print',
            component: () => import('../views/volunteer/badges/main/badges-main-users-list.vue'),
            props: ({ params }: any) => ({
              userId: parseInt(params.userId),
              badgeId: parseInt(params.badgeId),
            }),
            meta: {
              title: 'Print Badge',
              rootPage: true
            }
          }
        ]
      },
    ]
  },
  {
    path: 'group-call',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'group-call',
        component: () => import('../views/video-call/group-call.vue'),
        meta: {
          title: 'Group Call Home',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'zoom-call',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'zoom',
        component: () => import('../views/video-call/zoom.vue'),
        meta: {
          title: 'Zoom',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'wnmeet',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'wnmeet',
        component: () => import('../views/video-call/wnmeet.vue'),
        meta: {
          title: 'WN Meet',
          rootPage: true
        }
      }
    ]
  },
  {
    path: 'chats',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'chats-view',
        component: () => import('../views/chats-service/chats-view.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Chat',
          rootPage: true,
          fullscreen: true
        }
      }
    ]
  },
  {
    path: 'speakers',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'speakers',
        component: () => import('../views/speakers/speakers.vue'),
        meta: {
          title: 'Speakers',
          isForbiddenOnGeneral: true
        }
      }
    ]
  },
  {
    path: 'showcase/:id?',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'showcase-requests',
        component: () => import('../views/showcase/showcase-requests-list.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Showcase Requests',
          rootPage: true
        }
      },
      {
        path: 'showcase-game/:requestId',
        name: 'showcase-request',
        component: () => import('../views/showcase/game-view.vue'),
        props: ({ params }: { params: { requestId: string, id: string }}) => ({
          requestId: parseInt(params.requestId),
          showcaseSlug: params.id,
        }),
        meta: {
          title: 'Showcase Request'
        }
      }
    ]
  },
  {
    path: 'showcase-top/:id?',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'showcase-top',
        component: () => import('../views/showcase/showcase-requests-list.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id,
          onlyTop: true
        }),
        meta: {
          title: 'Showcase Top',
          rootPage: true
        }
      },
      {
        path: 'showcase-game/:requestId',
        name: 'showcase-request-top',
        component: () => import('../views/showcase/game-view.vue'),
        props: ({ params }: { params: { requestId: string, id: string }}) => ({
          requestId: parseInt(params.requestId),
          showcaseSlug: params.id,
          onlyTop: true
        }),
        meta: {
          title: 'Showcase Request'
        }
      }
    ]
  },
  {
    path: 'games/:id',
    component: () => import('../layouts/main/main-entry.vue'),
    meta: {
      isForbiddenOnGeneral: true
    },
    children: [
      {
        path: '',
        name: 'showcase',
        redirect: { name: 'showcase-requests' },
      },
      {
        path: 'game/:gameId',
        name: 'game-view',
        redirect: ({ params }) => ({
          name: (params.eventSlug ? EVENT_ROUTE_PREFIX : '') + 'single-game-view',
          params: {
            eventSlug: params.eventSlug,
            id: params.gameId
          }
        })
      }
    ]
  },
  {
    path: 'game/:id',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'single-game-view',
        component: () => import('../views/showcase/game-view.vue'),
        props: ({ params }: RouterGameProps) => ({
          gameId: parseInt(params.id),
        }),
        meta: {
          title: 'Game',
        }
      }
    ]
  },
  {
    path: 'academy-admin',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/academy/only-course-staff.vue'),
        children: [
          {
            path: '',
            name: 'academy-admin-home',
            redirect: { name: 'academy-admin-students' },
          },
          {
            path: 'students',
            name: 'academy-admin-students',
            component: () => import('../views/academy-admin/students.vue'),
            meta: {
              title: 'Academy Students',
              rootPage: true,
            }
          },
          {
            path: 'homeworks',
            name: 'academy-admin-homeworks',
            component: () => import('../views/academy-admin/homeworks.vue'),
            meta: {
              title: 'Academy Homework',
              rootPage: true,
            }
          },
          {
            path: 'promo-messages',
            name: 'academy-admin-promo-messages',
            component: () => import('../views/academy-admin/promo-messages.vue'),
            meta: {
              title: 'Academy Promo Messages',
              rootPage: true,
            }
          },
          {
            path: 'stream-records',
            name: 'academy-admin-stream-records',
            component: () => import('../views/academy-admin/stream-records.vue'),
            meta: {
              title: 'Stream Records',
            }
          },
          {
            path: 'lectures-archive',
            name: 'academy-admin-lectures-archive',
            component: () => import('../views/academy-admin/lectures-archive.vue'),
            meta: {
              title: 'Lectures Archive',
            }
          },
          {
            path: 'settings',
            name: 'academy-admin-settings',
            component: () => import('../views/academy-admin/settings.vue'),
            meta: {
              title: 'Settings',
            }
          },
        ]
      },
      {
        path: 'modules',
        component: () => import('../components/academy/only-course-admin.vue'),
        children: [
          {
            path: '',
            name: 'modules-list',
            component: () => import('../views/academy-admin/modules/modules-list.vue'),
            meta: {
              title: 'Modules List',
              rootPage: true,
            }
          },
          {
            path: ':id',
            name: 'module-view',
            component: () => import('../views/academy-admin/modules/module-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id),
            }),
            meta: {
              title: 'View Module',
              rootPage: true,
            }
          },
          {
            path: ':id/edit',
            name: 'module-edit',
            component: () => import('../views/academy-admin/modules/module-form.vue'),
            props: ({ params }: RouterProps) => ({
              id: parseInt(params.id),
            }),
            meta: {
              title: 'Edit Module',
              rootPage: true,
            }
          },
          {
            path: 'create',
            name: 'module-create',
            component: () => import('../views/academy-admin/modules/module-form.vue'),
            meta: {
              title: 'Create Module',
              rootPage: true,
            }
          },
          {
            path: ':moduleId/lessons',
            component: emptyRouteComponent,
            children: [
              {
                path: '',
                name: 'lessons-list',
                component: () => import('../views/academy-admin/lessons/lessons-list.vue'),
                props: ({ params }: RouterLessonProps) => ({
                  moduleId: parseInt(params.moduleId),
                }),
                meta: {
                  title: 'Lessons List',
                  rootPage: true,
                }
              },
              {
                path: ':id',
                name: 'lesson-view',
                component: () => import('../views/academy-admin/lessons/lesson-view.vue'),
                props: ({ params }: RouterLessonProps) => ({
                  id: parseInt(params.id),
                  moduleId: parseInt(params.moduleId),
                }),
                meta: {
                  title: 'Lesson',
                  rootPage: true,
                }
              },
              {
                path: ':id/edit',
                name: 'lesson-edit',
                component: () => import('../views/academy-admin/lessons/lesson-form.vue'),
                props: ({ params }: RouterLessonProps) => ({
                  id: parseInt(params.id),
                  moduleId: parseInt(params.moduleId),
                }),
                meta: {
                  title: 'Edit Lesson',
                  rootPage: true,
                }
              },
              {
                path: 'create',
                name: 'lesson-create',
                component: () => import('../views/academy-admin/lessons/lesson-form.vue'),
                props: ({ params }: RouterLessonProps) => ({
                  moduleId: parseInt(params.moduleId),
                }),
                meta: {
                  title: 'Create Lesson',
                  rootPage: true,
                }
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'publishers',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'publishers',
        component: () => import('../views/publishers/publishers-list.vue'),
        meta: {
          title: 'Publishers Pavilion',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      },
    ]
  },
  {
    path: 'eventhub/index.html',
    redirect: route => {
      const { event, action, entity, redirect } = route.query

      if (redirect) {
        if (['expo', 'lectures'].some(fragment => redirect.includes(fragment))) {
          return { path: redirect, query: { chat: 'open' }}
        }
        return redirect as string
      }

      return usePushService().getRouteByPushData(event as string, entity as string, action as PushActionType)
    }
  },
  {
    path: 'media',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'media',
        component: () => import('../views/media/media-list.vue'),
        meta: {
          title: 'Media',
          rootPage: true,
          isForbiddenOnGeneral: true
        }
      },
    ]
  },
  {
    path: 'voting',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: ':id?',
        name: 'voting',
        component: () => import('../views/voting/voting-root.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          title: 'Voting',
          rootPage: true,
          isForbiddenOnGeneral: true
        },
        children: [
          {
            path: '',
            name: 'voting-view',
            component: () => import('../views/voting/voting-view.vue'),
            props: ({ params }: RouterProps) => ({
              id: params.id
            }),
            meta: {
              title: 'Voting'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'auth',
    component: emptyRouteComponent,
    redirect: { name: 'auth-login' },
    meta: {
      guest: true,
    },
    children: [
      {
        path: 'recovery',
        name: 'auth-recovery',
        component: () => import('../views/auth/auth-recovery/auth-recovery-entry.vue')
      },
      {
        path: 'reset-password',
        name: 'auth-reset',
        component: () => import('../views/auth/auth-reset.vue')
      },
      {
        path: 'register',
        name: 'auth-register',
        component: () => import('../views/auth/auth-register.vue')
      },
      {
        path: 'magic-code',
        name: 'auth-magic-code',
        component: () => import('../views/auth/auth-magic-code.vue')
      },
    ]
  },
  {
    path: 'auth',
    component: emptyRouteComponent,
    redirect: { name: 'auth-login' },
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('../views/auth/auth-login/auth-login-entry.vue')
      },
      {
        path: 'magic/:id',
        name: 'auth-magic',
        component: () => import('../views/auth/auth-magic.vue'),
        props: ({ params }: RouterProps) => ({
          id: params.id
        }),
        meta: {
          allowAuthorized: true,
        }
      },
      {
        path: 'google',
        name: 'auth-google',
        component: () => import('../views/auth/auth-google.vue')
      },
    ]
  },
  {
    path: 'program-shared',
    name: 'program-shared',
    component: () => import('../views/program/program-shared-view.vue'),
    meta: {
      guest: true,
      isForbiddenOnGeneral: true
    },
  },
  {
    path: 'academy',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: 'program',
        name: 'academy-program',
        component: () => import('../views/academy-student/program.vue'),
        meta: {
          title: 'Course program',
          rootPage: true
        }
      },
      {
        path: 'lesson/:id',
        name: 'academy-lesson',
        component: () => import('../views/academy-student/lesson.vue'),
        props: ({ params }: RouterProps) => ({
          id: parseInt(params.id),
        }),
        meta: {
          title: 'Lesson',
          rootPage: true,
        }
      },
    ]
  },
  {
    path: 'events',
    component: () => import('../layouts/main/main-entry.vue'),
    children: [
      {
        path: '',
        name: 'events',
        component: () => import('../views/events/events-view.vue'),
        meta: {
          title: 'Events',
          rootPage: true
        }
      },
    ]
  },
  {
    path: 'forbidden',
    name: Routes.Forbidden,
    component: () => import('../views/errors/forbidden.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: 'error',
    name: Routes.ServerError,
    component: () => import('../views/errors/server-error.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: 'profile-data',
    component: () => import('../layouts/basic.vue'),
    children: [
      {
        path: '',
        name: 'fill-profile',
        component: () => import('../views/fill-profile/fill-profile-view.vue')
      },
    ]
  },
  {
    path: ':pathMatch(.*)*',
    name: '404',
    component: () => import('../views/errors/not-found.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: ':pathMatch(.*)*',
    name: Routes.ServerError,
    component: () => import('../views/errors/server-error.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: ':pathMatch(.*)*',
    name: Routes.NetworkError,
    component: NetworkError,
    meta: {
      guest: true,
    }
  },
]

// note that we prefix only names and not paths, that maybe inportant for redirects
function prefixRouteNames(route: RouteRecordRaw, prefix: string): RouteRecordRaw {
  return {
    ...route,
    ...(route?.name && { name: `${prefix}${String(route?.name || '')}` }),
    ...(route?.redirect && typeof route.redirect === 'object' && 'name' in route.redirect && route?.redirect?.name && {
      redirect: {
        ...route.redirect,
        name: `${prefix}${String(route?.redirect?.name || '')}`
      }
    }),
    children: (route?.children || []).map(child => prefixRouteNames(child, prefix))
  }
}

export default [
  {
    path: '/',
    component: emptyRouteComponent,
    children: [
      ...isGeneral
        ? [...commonRoutes,
          {
            path: 'events/:eventSlug',
            name: Routes.Event,
            component: emptyRouteComponent,
            children: [
              ...commonRoutes.map(child => prefixRouteNames(child, EVENT_ROUTE_PREFIX)),
            ]
          }]
        : commonRoutes,
    ]
  },
]
